import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'slider',
    'container',
  ]
  connect() {
    // console.log("RangeController connected");
    $(this.sliderTarget).ionRangeSlider({
      onChange: this.updateValue.bind(this),
    });

    // Listen for the range change event
    this.element.addEventListener("range:change", (event) => {
      var values = this.sliderTarget.value.split(";");
      $(this.containerTarget).empty();
      if(values[0] == 1 && values[1] == 9) {
        var f1 = $("<input>").attr({
          type: "hidden",
          class: "astatement-values-field",
          name: "statement[values][]",
          id: "statement_values_0",
          value: null,
        });
        $(this.containerTarget).append(f1);
      } else {
        var f1 = $("<input>").attr({
          type: "hidden",
          class: "astatement-values-field",
          name: "statement[values][]",
          id: "statement_values_0",
          value: values[0],
        });
        var f2 = $("<input>").attr({
          type: "hidden",
          class: "astatement-values-field",
          name: "statement[values][]",
          id: "statement_values_1",
          value: values[1],
        });
        $(this.containerTarget).append(f1);
        $(this.containerTarget).append(f2);
      }
      
      // Perform any necessary actions here
      this.element.requestSubmit();
    });
  }

  updateValue(data) {
    // Get the current value and dispatch a custom event
    this.element.dispatchEvent(new CustomEvent('range:change', { detail: data }));
  }

  handleChange(event) {
    console.log("range changed");
    //this.element.requestSubmit();
  }
}
