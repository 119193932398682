import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';

export default class DonutChartController extends Controller {

  connect() {
    console.log("DonutChartController#connect")
    let elem = document.getElementById('emailStatusChart')
    if(Chart.getChart(elem)) {
      console.log("Chart already exists")
      return
    }

    this.render()

    const chartEvent = new CustomEvent("blue:update-chart")
    document.dispatchEvent(chartEvent)
  }

  render(){
    let total = Object.values(this.stats)[0]
    console.log(total)

    var labels = Object.keys(this.stats)
    var values = Object.values(this.stats)

    labels.shift()
    values.shift()

    labels = labels.map(this.humanize)

    new Chart(document.getElementById('emailStatusChart'), {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [{
          data: values,
          backgroundColor: ['#2C7BE5', '#A6C5F7', '#D2DDEC']
        }]
      }
    });
  }

  humanize(str) {
    return str
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/[_\s]+/g, ' ')
        .replace(/^[a-z]/, function(m) { return m.toUpperCase() })
  }

  get dataSet() {
    return this._dataSet = this._dataSet || this.statEle.dataset
  }

  get stats() {
    return this._getStats = this._getStats || JSON.parse(this.dataSet.results)
  }

  get statEle() {
    return this._ele = this._ele || document.getElementById(this.element.dataset.statId)
  }
}