// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"

// import "./theme/theme"
import "./theme/theme"

import "jquery";
import "ion-rangeslider";

// Make jQuery available globally
window.$ = window.jQuery = require("jquery");

