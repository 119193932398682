import { Controller } from "@hotwired/stimulus"

import Choices from "choices.js";

export default class extends Controller {
  static targets = ['field']
  connect() {
    // console.log("MutliSelectController connected")
    // console.log(this.element.id)
  }

  handleChange(event) {
    console.log("select changed")
    this.element.requestSubmit()
  }
}